.consultant_main{
    position: relative;
    /* display: flex; */
    /* justify-content: center; */
    /* flex-direction: column; */
    /* align-items: center; */
    margin: 0 auto;
    min-height: 80vh;
    width: 900px;
    /* text-align: center; */
    background-color: white;
    padding: 30px;
    margin-top: 2%;
    border-radius: 25px;
    /* margin-bottom: 20px; */
    max-width: 100vw;
}

.consultant_cont{
    max-width: 100%;
    /* width: 800px; */
    margin: 0 auto;
    /* bakc */
}

.consultant_profile{
    display: flex;
    width: auto;
}

.consultant_settings{
    background-color: #0c0847 ;
}



.consultant_header{
    width: 100%;
    /* height: 150px; */
    /* background-color: yellow; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

.profile_main{
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile_Img{
    border-radius: 50%;
    height: 40px;
    width: 40px;
    overflow: hidden;
}

.settings_icon_contanier{
    position: absolute;
    top: 50px;
    right: 50px;
    height:25px;
    width:25px;
    background-color:#afba15;
    opacity:0.7;
    cursor: pointer;
}

.settings_icon{
   color: white;
   text-align: center;
   cursor:pointer;
}

.profile_update{
    /* position: absolute; */
    top: 145px;
    margin-left: 45px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    color: white;
    cursor: pointer;
    background-color: #0c0847;
}

.consultant_nav{
    /* position: absolute; */
    display: flex;
     justify-content: center;
    align-items: center;
    margin-top: 30px;
    /* flex-wrap: wrap; */
    /* top: 310px; */
    /* margin-bottom: 20px; */
}
.feature_buttons>div{
    margin:  15px 30px ;
    cursor: pointer;
    width: 140px;
    height: 140px;
    border-radius: 10px;
    transition: all 0.3 ease-in;
    color: white;
}
.feature_buttons>div:hover{
    transform: scale(1.1);
 }

.login_text{
    /* position: absolute; */
    top: 200px;
    border-bottom:1px solid #afba15;
    width: 100%;
}

.login-contanier{
    /* display: flex;
    justify-content: center;
    align-items: center; */
    width: 100%;
    margin: 0 auto;
}
.defaultImg{
    width: 40px;
}

/* a:hover,a:visited,a:active{
    text-decoration: none;
    
} */




@media screen and (max-width:767px){
    .login_main{
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
    }
    
}

@media screen and (max-width: 767px) {
    .consultant_nav {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  
    .feature_buttons > div {
      margin: 15px 0;
      width: 100%;
      height: auto;
    }
  }
  
  