html {
  background-color: whitesmoke;

}

body {
  background-color: whitesmoke;

}

#title {
  text-align: center;
  font-family: arial, sans-serif;
}

#students {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 100%;
}

#students td,
#students th {
  border: 1px solid #ddd;
  padding: 8px;
}

#students tr:nth-child(even) {
  background-color: #f2f2f2;
}

#students tr:hover {
  background-color: #ddd;
}

#students th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4caf50;
  color: white;
}
