.forgot-password-container {
  width: 100vw;
}

.forgot-password-container .form {
  margin: auto;
  padding: 10px;
  width: 100%;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


.forgot-password-container .form .signup-confirm {
  margin-top: 20px;
}

.form {
    margin: auto;
    padding: 10px;
    width: 100%;
    background-color: whitesmoke;
  }
  
  /* input {
    border: 1px solid #62680a;
  } */
  
  .btn-dark {
    background-color: #071850;
    color: whitesmoke;
    border: 1px solid #03091d;
    float: right;
  }
  
  .btn-dark:hover {
    background-color: #030d2b;
    border: 1px solid #03091d;
  }
  
  .btn-dark:active {
    background-color: #030d2b;
    border: 1px solid #03091d;
  }
  
  .card {
    color: rgb(59, 59, 59);
    background-color: rgb(238, 238, 238);
    border: none;
    border-radius: 5px;
    padding: 10px 0 10px 0;
  }

  .signup-input {
    width: 100%;
    height: 63px;
    left: 528px;
    top: 387px;
  
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }
  
  .placeholder-input::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    font-size: 25px;
    line-height: 38px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.017em;
  
    color: #2E2687;
  
  }
  
  .signup-confirm {
 
    background: #2E2687;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 50px;
    align-self: center;
  
  }
  .signup-confirm:hover {
    background-color: #02001a;
    border-color: white;
    color: white;
  }
  .signup-confirm:focus {
    background-color: #02001a;
    border-color: white;
  }
  .signup-confirm:active {
    background-color: #0c0847;
    border-color: white;
  }
  