.dropdown {
  border-radius: 0.25rem;
}

.dropdown-toggle {
  flex: 1;
  border-radius: 0.25rem;
}

.dropdown-input-right {
  flex: 1;
  border-radius: 0 0.25rem 0.25rem 0;
}

.dropdown-input-left {
  flex: 1;
  border-radius: 0.25rem 0 0 0.25rem;
}

.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown-menu {
  width: 100%;
}

.dropdown-item {
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.grey {
  background-color: lightgray;
}
.grey:hover {
  background-color: gray;
}

.green {
  background-color: #afba15;
}
.green:hover {
  background-color: #889113;
}

.signup-input {
  height: 63px;
  left: 528px;
  top: 387px;

  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}