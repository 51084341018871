.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  color: aliceblue;
}

.fc-time-grid .fc-content-skeleton {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0; }
to

.fc-time-grid .fc-content-skeleton {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  height: 100%; }

  .inputField {
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px;
    font-size: 16px;
  }
  
  .button {
    /* Add any styles for the button */
  }
  