/* Add this to your CSS file or style tag */
.freelancer-tag {
    position: absolute;
    right: 10px; /* Adjust the left position as needed */
    background-color: #ffffff;; /* Set your desired background color */
    color: #07094a; /* Set your desired text color */
    padding: 3px 6px;
    border-radius: 10px;
    border: 2px solid #0d0e3b;; /* Set your desired border color */
    font-size: 12px; /* Adjust the font size as needed */

  }


  