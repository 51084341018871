.localVid{
    width: 100%;
    height: 60vh;
}

.remoteVid{
    position: absolute;
    width: 30vh;
    height:150px;
    /* background-color: black; */
    bottom: 20px;
    right: 20px;
    /* border-radius: 5px; */

}

.localAud{
    width: 100%;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000bd;

    
}

.remoteAud{
    position: absolute;
    width: 30vh;
    height:150px;
    /* background-color: black; */
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000bd;
    border-radius: 5px;

}

.phonesm{
    /* font-size: 40px; */
    padding: 5px;
    border-radius: 50%;
    color: white;
    background: #32818ab9;
    

}

.phonebg{
    /* font-size: 100px; */
    padding: 4px;
    border-radius: 50%;
    color: #32818a;
    background: #1919194a;
}

.subVid{
height: 100%;
border-radius: 5px;
border: 1px solid white;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.streamCont{
   
    border: 1px solid rgb(163, 163, 163);
    border-radius: 5px;
    position: relative;
}