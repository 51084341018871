*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



.homepage-contanier{
    display: flex;
    justify-content: center;
    align-items: center;
}

.homepage-main{
    background: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.4)), url("../../../../../images/farm1.jpg");
    background-position: center;
    background-size: cover; 
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    width: 50%;
    color: white;
    /* text-align: center; */
    background-color: white;

}
.logo-btn{
    top: 20px;
    left: 18px;
    position: absolute;
    width: 170px;
}
.home-btn{
margin-top: 40px;
display: flex;
justify-content: center;
align-items: center;
}

.home-btn>button{
    margin: 30px;
    width: 140px;
    height: 40px;
    outline: none;
    cursor: pointer;
    border: none;
    transition: all 0.1s ease-in-out;
    color: white;
    background-color:#afba15 ;
}


.home-btn>button:hover{
/* transform: scale(1.1); */
/* border-radius: 20px; */

}

/* MEDIA QUERIES */
@media screen and (max-width:767px){
    .homepage-main{
        width: 100vw !important;
        height: 100vh;
    }
    .homepage-main>h1{
        font-size: 25px;
        padding: 10px;
        
    }

    .homepage-main>p{
        padding: 12px;
  
        margin-top: 10px;
    }
    .home-btn{
        display: flex;
        padding: 0px 10px;
        justify-content: space-between;
      
    }
    
}
@media screen and (max-width:420px){
    .home-btn{
       width: 100vw;
    }
}