.chat_heading{
    font-weight: 500;
    overflow: hidden;
    font-size: 16px;

}

.endChat{
    position: absolute;
    top: 4px;
    right: 4px;
}
.chats_cont{
    max-height: 90vh;
    display: flex;
    border: 1px solid #dee2e6 ;
}
/* a,a:hover,a:active{
    text-decoration: none;
    color: black

} */

.chat_cont{
    padding: 8px;
    width: 100%;
}


li{list-style: none;}

.chat_card{
    /* width: 40%; */
    height:100px;
    border: 1px solid #dee2e6;
    padding: 10px;
    /* margin-bottom: 20px; */
    overflow:hidden;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    
}



.notification{
    width: 8px;
    height: 8px;
    background-color: green;
    position: absolute;
    right: 10px;
    bottom: 10px;
    border-radius: 50%;
}

/* .chats_cont > div{
    height: 100%;
    overflow-y: scroll;
} */

.chatsCont{
  
    padding-top: 15px;
}
.chat_cards{
    height: 100%;
}
.chat_topic{
    font-size: 14px;
}

.active{
    background-color:#48627f;
    color: white
}
/* a,a:hover,a:active{
    color: black;
} */

.chat_left{
    height: 90vh;
    width: 290px;
    overflow-y: scroll;
    border-top: 1px solid #dee2e6 ;
    border-bottom: 1px solid #dee2e6 ;
}

.chat_left a ,.chat_left a:hover{
    text-decoration: none;
}

.chat_txt{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0;
    width: 100%;
}






/* width */
::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #606060;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #484848;
    border-radius: 10px;
  }