html {
  background-color: whitesmoke;
}

body {
  background-color: whitesmoke;
  width: 100%;
  height: 100%;

}

/* html, body {
  height: 100%;
  margin: 0;
  padding: 0;
} */


* {
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.06rem;
  /* font-size: 20px; */
  /* font-weight: 900; */
}

.warning {
  color: rgb(204, 37, 37);
  font-size: 16px;
}

/* About us page */
.contrast {
  margin-left: -1rem;
  margin-right: -1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #d9dd99;
  padding: 2%;
  /* ignoring parent padding => 100% + 2*padding */
  width: calc(100% + 2rem);
}
.no-contrast {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.3rem;
  padding: 2rem;
}

.breaker {
  margin-left: -1rem;
  margin-right: -1rem;
  height: 30vh;
  overflow: hidden;
}
.breaker img {
  position: relative;
  object-fit: cover;
  /* ignoring parent padding => 100% + 2*padding */
  width: calc(100% + 2rem);
  top: -100%;
}

/* page not found */

.not-found .page-not-found {
  font-size: 45px;
  font-weight: 600;
  color: rgb(55, 85, 54);
}

.not-found .not-found-message {
  font-size: 23px;
  padding-top: 10px;
}

.fix-image {
  width: 100% \9;
  object-fit: scale-down;
}

.signup .signup-logo {
  font-size: 38px;
  color: #333333;
  padding: 2px 5px 10px 5px;
}

.login p,
.signup p {
  padding-top: 30px;
  font-size: 17px;
}

.login h1,
.signup h1 {
  padding-top: 5px;
  font-size: 18px;
  font-weight: 700;
}

.signup .header {
  color: rgb(55, 85, 54);
}

.login .forgot-password {
  font-size: 16px;
  margin-top: 5px;
  float: right;
  text-decoration: none;
  color: rgb(90, 91, 92);
}

.login .register,
.signup .register,
.frg-pass .register {
  color: #aab41e;
  font-weight: 600;
  text-decoration: none;
}

.signup .terms {
  font-size: 15px;
  text-decoration: none;
}

.signup .termcond {
  color: #757c0e;
  text-decoration: none;
}

.terms-link,
.privacy-link {
  color: #aab41e;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
}

/* contact page */
.contact {
  margin: auto;
  width: 95%;
  text-align: center;
}

.contact .contact-logo {
  /*color: rgb(81, 117, 79);*/
  color: #aab41e;
  font-size: 50px;
  padding: 15px 15px 15px 15px;
}

.contact h1 {
  font-size: 1.2rem;
  color: rgb(55, 85, 54);
}

.contact-text-layout {
  margin: auto;
  padding-top: 2%;
}

.details {
  text-align: center;
}
.details .contact-footer-icon {
  font-size: 30px;
  color: rgb(55, 85, 54);
  padding: 5px 10px 10px 10px;
}

.details p {
  font-size: 13px;
  padding-top: 5px;
}

.details .more-details-header {
  font-size: 20px;
}

/* forgot password page and change password page and user account page */

.frg-pass h1,
.change-password h1 {
  font-size: 18px;
  font-weight: 700;
}

.frg-pass .remember-password,
.change-password .cancel {
  font-size: 16px;
  font-weight: 600;
  color: rgb(55, 85, 54);
  text-decoration: none;
}

.frg-pass .rmb-pass,
.change-password .back-to-acc {
  padding-top: 50px;
}

.frg-pass .no-acc {
  padding-top: 28px;
}

.label {
  background-color: #0c656b;
  color: white;
  font-weight: 600;
  padding-left: 15.5px;
  padding-right: 15.5px;
}

.large-img {
  max-width: 100%;
  height: auto;
}

.image {
  object-fit: contain;
  max-width: 100%;
}

.web-center {
  /* float: center; */
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
  /*600px in center of large page but varies when browser is smaller*/
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: 'Poppins', sans-serif;
}


/* map page */
.map-data {
  width: 100%;
  height: 60vh;
}

/* Map Clusters */
.marker-cluster div {
  background-color: #afba15 !important;
}

.marker-cluster {
  background-color: #afba15 !important;
}

.popup-data {
  padding: 0px 2px 0px 2px;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
}

.popup-name {
  color: rgb(55, 85, 54);
  font-size: 17px;
  font-weight: 600;
  padding: 3px 4px 1px 4px;
}

.auth-error {
  color: red;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
}

@media (max-width: 576px) {
  .home {
    background-image: none;
    background-color: whitesmoke;
  }

  .home-welcome {
    font-size: 15px;
    width: 100%;
  }

  .update-text {
    font-size: 12px;
  }

  .home-welcome,
  .update-text {
    color: black;
  }
}

#root {
  width: 100%;
  height: 100%;
}

.logo_img{
  width: 200px;
}

@media (max-width: 600px) {
  .card-design {
    width: 203px;
    height: 300px;
  }

  .avatar-name-container {
    flex-direction: column;
  }

  .logo_img{
    width: 180px;
  }

  .avatar-image{
    margin-bottom: 10px;
  }
  .profile-container {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
}

.card-design {
  width: 261px;
  height: 400px;
}


.basic-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 550;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  text-align: center;
}

.basic-title-left {
  letter-spacing: 0.1rem;
  text-align: left;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 530;
  font-size: 18px;
  line-height: 24px;
}

.basic-lg {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: #f4f4f4;
}

.basic-text {
  font-family: 'Poppins', sans-serif;
  font-weight: 550;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  text-align: center;
}

.form_search {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}

.button_text {
  font-family: 'Poppins', sans-serif;
  font-weight: 530;
  font-size: 10px;
}

.dropdown_text p {
  font-family: 'Poppins', sans-serif;
  font-weight: 530;
  font-size: 14px;
  padding-top: 8px;
}

.modal-header {
  font-family: 'Poppins', sans-serif;
  background-color: #A5B828;
  color: #1C1569;
  align-items: center;
}

.scan-text {
  font-family: 'Poppins', sans-serif;
  color: #1C1569;
  background-color: #A5B828;
  padding: 10px;
  border-radius: 19px;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease;

  /* Optional hover effect */
  &:hover {
    background-color: #1C1569;
    color: #eeeeee;
  }
}

.custom-form {
  width: 90%; /* Adjust the width to your desired value */
  margin: 0 auto; /* Center the form horizontally if needed */
}

/* .signup-input {
  background: #FFFFFF;
  border-radius: 20px;
} */

.signup-input-meal-name {
  background: #FFFFFF;
  border-radius: 20px;
  height: 51px;
  display: inline-block;
  /* vertical-align: middle; */
  width: calc(50% - 10px); /* Adjust the width to your desired value */
  margin-right: 10px; /* Adjust the margin to provide spacing between the inputs */
}

.signup-input-qty {
  background: #FFFFFF;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: inline-block;
  /* vertical-align: middle; */
  width: calc(60% - 5px); /* Adjust the width to your desired value */
  height: 51px;
}

.dropdown-input {
  display: inline-block;
  vertical-align: middle;
  
}

.ingredient-list ul {
  color: #1C1569;
  align-self: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 550;
  letter-spacing: 0.1rem;
  text-align: center;
  list-style-type: none;

}



.signup-confirm {
  margin: auto;
  margin-top: 9%;
  margin-bottom: 2%;
  font-size: 1rem;
  border-radius: 8px;

  background-color: #0c0847;
  border-color: #02001a;
  color: white;

  width: 135px;
  height: 51px;
  left: 627px;
  top: 757px;

  background: #2E2687;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 50px;

}
.signup-confirm:hover {
  background-color: #02001a;
  border-color: white;
  color: white;
}
.signup-confirm:focus {
  background-color: #02001a;
  border-color: white;
}
.signup-confirm:active {
  background-color: #0c0847;
  border-color: white;
}

.save-meal-check-box {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the gap as needed */
}

.save-meal-check-box input[type="checkbox"] {
  transform: scale(1.5); /* Increase the scale factor as needed */
}

.save-meal-check-box label {
  font-size: 20px; /* Increase the font size as needed */
}

.avatar-name-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-image {
  width: 45%;
  text-align: center;
}

.avatar-image img {
  max-width: 100%;
}

.profile-container {
  /* flex: 1; */
  width: 45%;
  /* margin-left: 10px; Adjust margin as needed */
}


.name-container {
  margin-right: 250px; /* Adjust the margin as needed */
}

/* .tabs {
  display: inline-block;
}

.tabs .MuiTabList-root {
  display: flex !important;
  align-items: center !important;
  padding: 24px;
}

.tabs .MuiTab-root {
  flex: 1 !important;
} */

.tabs .MuiTabPanel-root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

}



/* .tabs .MuiTabPanel-root {
  flex: 1;
} */


.banner {
  height: 300px; /* Adjust the height based on your design */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff; /* Text color */
  font-family: 'Poppins', sans-serif; /* Use your preferred font */
}

.banner-text {
  font-size: 2.5rem; /* Adjust the font size */
  font-weight: bold;
}

.banner-list {
  margin-top: 10px; /* Adjust the spacing between heading and list */
}



/*=============================================
=            Admin page css            =
=============================================*/
.adminCont{
  width : 100%;
  display: flex;
  background-color: #fff;
  min-height: 100vh;

}
.admin_header{
  height: 30px;
  background-color: purple;
}

.admin_left_section{
   width: 70%; ;
  background-color:#f8f8f8 ;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.admin_left_section > div:not(:last-child){
  margin-bottom: 1px;

}

.admin_right_section{
  position: fixed;
  /* width: 90%; */
  width: calc(100% - 70%);
  background-color: #f5f5f5;
  height: calc(100% - 30px);
  right: 0;
}

.admin_paginator{
    z-index: 1;
    
    position: fixed;
   
    bottom: 30px;
    left: 30%;
    
}

.admin_calendar_mock{
  width: 85%;
  height: 360px;
  background-color: #7acfd5;
  margin: 0 auto;
}


/*=====  End of Admin page css  ======*/


