.landing {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../images/peppers.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}


.title {
  position: absolute;
  text-align: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 10%;
  transform: translateY(-0.9%);
}

.title img {
  max-width: 100%;
}



.buttons {
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* Phones */
@media screen and (max-width: 600px) {
  .buttons {
    width: 90%;
    left: 5%;
    bottom: 10%;
  }

  .title img {
    max-width: 50%;
  }
}

/* Portrait Tablet and Large Phones */
@media screen and (min-width: 600px) {
  .title {
    width: 90%;
    margin-left: 5%;
    top: 1%;
  }
  .title img {
    max-width: 75%;
  }

  .landing {
    width: 90%;
    height: 100%;
    left: 5%;
  }
  .buttons {
    width: 60%;
    left: 20%;
    top: 78%;
  }
}

/* Landscape Tablet */
@media screen and (min-width: 768px) {
  .title {
    width: 75%;
    margin-left: 12.5%;
    top: 2%;
  }
  .title img {
    max-width: 70%;
  }

  .landing {
    width: 70%;
    height: 100%;
    left: 15%;
  }
  .buttons {
    width: 50%;
    left: 25%;
    top: 78%;
  }
}

/* Laptop/Desktop */
@media screen and (min-width: 992px) {
  .title {
    width: 75%;
    margin-left: 12.5%;
    top: 2%;
  }
  .title img {
    max-width: 30%;
  }
  .landing {
    width: 60%;
    height: 100%;
    left: 20%;
  }
  .buttons {
    width: 50%;
    left: 25%;
    top: 78%;
  }
}

/* Large Laptop/Desktop */
@media screen and (min-width: 1200px) {
  .title {
    width: 75%;
    margin-left: 12.5%;
  }
  .title img {
    max-width: 20%;
  }
  .landing {
    width: 50%;
    height: 100%;
    left: 25%;
  }
  .buttons {
    width: 40%;
    left: 30%;
    top: 78%;
  }
}
/* landscape styles */
/* @media screen and (orientation : landscape){
  .landing {
      width: 100vw;
      height: auto;
      
  }
.buttons{
 top:170%;
}
.title img {
  height: 50vh;
}} */

.login-buttons-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px; /* Adjust the margin as needed */
}

/* Optional: Add styles for individual login buttons if needed */
.login-buttons-container button {
  /* Add your styles here */
}

.horizontal-buttons {
  display: flex;
  align-items: center; /* Align buttons at the center vertically */
}

