.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  
  .wallet-balance {
    display: flex;
    align-items: center; /* Vertically center the content */
  }
  
  .currency {
    font-size: 24px;
    margin-right: 5px; /* Add some spacing between "NGN" and the balance */
  }
  
  .balance {
    font-size: 32px;
  }
  
.card-details {
  margin-bottom: 0; /* Remove margin between elements */
}

  
  .custom-card {
    background-color: #160c67;
    position: relative;
    transition: transform 0.3s, box-shadow 0.3s;
    color: antiquewhite;
  }
  
  .custom-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.3); /* Overlay color with transparency */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .custom-card:hover .card-overlay {
    opacity: 1;
  }
  
  /* Additional styles for the powered by logo */
.powered-by-logo {
    display: flex;
    align-items: center;
    justify-content: center; /* Center horizontally */
    color: #160c67;
    font-size: 14px;
    flex-direction: column;
  }
  
  .powered-by-logo img {
    max-height: 200px;
    width: auto;
  }
  