.exp-date {
  display: flex;
  flex-direction: column;
  width: 6.5rem;
  height: 6.5rem;
  border: 1px solid #ececec;
  background-color: #040335;
  color: white;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
}

.exp-date__month {
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: -0.5rem;
}

.exp-date__year {
  font-size: 0.75rem;
}

.exp-date__day {
  font-size: 1.5rem;
  font-weight: bold;
}
