.item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  margin: 1rem 0;
  background-color: #abc035;
}

.item-b  {
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color:  #09068d;
  border: 1px solid white;
  padding: 0.5rem;
  border-radius: 12px;


}

.item-b :hover,
.item-b :active {
  background-color: #1463f5;
  border-color: #1463f5;
}

.item__description {
  display: grid;
  gap: 0.4rem;
}



.item h2 {
  color: #776a6a;
  font-size: 1rem;
  flex: 1;
  margin: 0 1rem;
  color: rgb(12, 8, 8);
}

.item__price {
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color:  #040335;
  border: 1px solid white;
  padding: 0.5rem;
  border-radius: 12px;
  text-align: center;
}

@media (min-width: 580px) {
  .item__description {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }

  .item__description h2 {
    font-size: 1.25rem;
  }

  .-item__price {
    font-size: 1.25rem;
    padding: 0.5rem 1.5rem;
  }
}