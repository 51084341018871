.main {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.map {
  width: 65%;
  height: 400px;
  position: relative;
  border: 2px solid #ccc;
  border-radius: 5px;
}

.coords {
  width: 30%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.coords h3 {
  text-align: center;
}

.coord-pair {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.coord-pair input {
  width: 35%;
  padding: 8px; /* Reduced padding */
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  font-size: 14px; /* Reduced font size */
}

.coord-pair .delete-icon {
  background: none;
  border: none;
  cursor: pointer;
}

.coord-pair .delete-icon img {
  width: 20px;
  height: 20px;
}

.coords button {
  padding: 8px 16px; /* Reduced padding */
  margin-top: 10px;
  background-color: #a0c94f;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 14px; /* Reduced font size */
  cursor: pointer;
}

.coords button:hover {
  background-color: #8baf3d;
}
