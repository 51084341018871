.paginator {
  width: 150px;
  height: 35px;
  border: 1px solid #e6e6e6;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  /* background-color: #fff; */
}

button,
button:focus,
button:active {
  outline: none;
  border: none;
}
input,
input:focus,
input:active {
  outline: none;
  /* border: 1px solid #a4a4a4; */
  text-align: left;
}

.paginator input {
  width: 2.4rem;
}

.paginator_form {
  display: flex;
  width: 5.2rem;
  justify-content: space-evenly;
}

.paginator input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.paginator input:focus {
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.paginatorBtn {
  width: 2.2rem;
  background-color: #4e8488;
  color: white;
}

.paginator_goBtn {
  background-color: black;
}

.paginatorBtn_left > svg {
  transform: rotate(90deg);
  fill: black;
}
.paginatorBtn_right > svg {
  transform: rotate(-90deg);
  fill: black;
}

.paginator_prev_pg {
  border-radius: 0 8 8 0;
}
.paginator_next_pg {
  border-radius: 8 0 0 8;
}
