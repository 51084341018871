/* map page */
.map-data {
  width: 100%;
  height: 60vh;
}

.marker-cluster-custom {
  border: 3px solid #ededed;
  border-radius: 50%;
  color: #262b2a;
  height: 50px;
  line-height: 34px;
  text-align: center;
  width: 50px;
}

.small {
  background: #58d68d;
}

.medium {
  background: #f5b041;
}

.large {
  background: #ec7063;
}

.mapContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  right: 10px;
  top: 10px;
  width: 20%;
  border-radius: 20px;
  z-index: 1000;
  /* background-color: rgba(255, 255, 255, 0.5); */
  /* backdrop-filter: blur(10px); */
  /* -webkit-backdrop-filter: blur(10px); */
}

/* position: "absolute",
display: "flex",
justifyContent: "center",
flexDirection: "column",
alignItems: "center",
right: "10px",
top: "10px",
width: "20%",
// height: "30%",
borderRadius: "20px",
zIndex: 1000,
backgroundColor: "rgba(255, 255, 255, 0.5)",
backdropFilter: "blur(10px)",
WebkitBackdropFilter: "blur(10px)", */