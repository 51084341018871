/*button styles*/
.default-btn {
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
  font-weight: 600;
  font-size: 3rem;
  border-radius: 8px;
  padding: 5% 0 5% 0;
}

.sub-btn {
  width: 60%;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 2%;
  font-size: 1.3rem !important;
  border-radius: 5px;
}

.icon-btn {
  width: 10.2rem;
  height: 10.2rem;
  padding: 1rem;
  overflow: hidden;
  margin: 3%;
  border-radius: 15px;
  /* display: ; */
  /* box-shadow: 4px 5px 5px -2px rgba(0, 0, 0, 0.5); */
}



.add-btn {
  width: 3rem;
  height: 3rem;
  margin: 0 0 0 5%;
  padding: 0rem;
  border-radius: 10px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*button colours*/
.turquoise-btn {
  background-color: #32797e;
  border-color: #0c656b;
  color: white;
  transition: transform 0.5s;
}
.turquoise-btn:hover {
  background-color: #0c656b;
  color: white;
}
.turquoise-btn:focus {
  background-color: #0c656b;
  border-color: white;
}
.turquoise-btn:active {
  background-color: #32797e;
  border-color: white;
}

.green-btn {
  background-color: #afba15;
  border-color: #afba15;
  color: white;
  transition: transform 0.5s;
}
.green-btn:hover {
  background-color: #c5d300;
  color: white;
}
.green-btn:focus {
  background-color: #c5d300;
  border-color: white;
}
.green-btn:active {
  background-color: #afba15;
  border-color: white;
}

.green-light-btn {
  background-color: #bfc563;
  border-color: #bfc563;
  color: rgb(0, 0, 0);
  transition: transform 0.5s;
}
.green-light-btn:hover {
  background-color: #d1d682;
  color: rgb(0, 0, 0);
}
.green-light-btn:focus {
  background-color: #bfc563;
  border-color: rgb(0, 0, 0);
}
.green-light-btn:active {
  background-color: #bfc563;
  border-color: rgb(0, 0, 0);
}

.yellow-btn {
  background-color: #d9a12d;
  border-color: #c58401;
  color: white;
  transition: transform 0.5s;
}
.yellow-btn:hover {
  background-color: #c58401;
  color: white;
}
.yellow-btn:focus {
  background-color: #c58401;
  border-color: white;
}
.yellow-btn:active {
  background-color: #d9a12d;
  border-color: white;
}

.blue-btn {
  background-color: #0c0847;
  border-color: #02001a;
  color: white;
  transition: transform 0.5s;
  border-radius: 15px;
}
.blue-btn:hover {
  background-color: #02001a;
  color: white;
}
.blue-btn:focus {
  background-color: #02001a;
  border-color: white;
}
.blue-btn:active {
  background-color: #0c0847;
  border-color: white;
}

.grey-btn {
  background-color: #787879;
  /* border-color: #02001a; */
  color: white;
  transition: transform 0.5s;
  border-radius: 15px;
}
.grey-btn:hover {
  background-color: #02001a;
  color: white;
}
.grey-btn:focus {
  background-color: #02001a;
  border-color: white;
}
.grey-btn:active {
  /* background-color: #0c0847; */
  border-color: white;
}


.red-btn {
  background-color: #840406;
  border-color: #02001a;
  color: white;
  transition: transform 0.5s;
  border-radius: 15px;
}
.red-btn:hover {
  background-color: #02001a;
  color: white;
}
.red-btn:focus {
  background-color: #02001a;
  border-color: white;
}
.red-btn:active {
  background-color: #0c0847;
  border-color: white;
}

.default-btn.grey {
  color: black;
  background-color: lightgray;
  border-color: gray;
}
.default-btn.grey:hover {
  background-color: gray;
  border-color: gray;
}
.default-btn.grey:focus {
  box-shadow: none !important;
}

.orange-btn {
  background-color: #e36b07;
  border-color: #a84d04;
  color: white;
  transition: transform 0.5s;
}
.orange-btn:hover {
  background-color: #a84d04;
  color: white;
}
.orange-btn:focus {
  background-color: #a84d04;
  border-color: white;
}
.orange-btn:active {
  background-color: #e36b07;
  border-color: white;
}

.pink-btn {
  background-color: #d81b60;
  border-color: #8f0e3f;
  color: white;
  transition: transform 0.5s;
}
.pink-btn:hover {
  background-color: #8f0e3f;
  color: white;
}
.pink-btn:focus {
  background-color: #8f0e3f;
  border-color: white;
}
.pink-btn:active {
  background-color: #d81b60;
  border-color: white;
}

.cyan-btn {
  background-color: #00a7a7;
  border-color: #007d7d;
  color: white;
  transition: transform 0.5s;
}
.cyan-btn:hover {
  background-color: #007d7d;
  color: white;
}
.cyan-btn:focus {
  background-color: #007d7d;
  border-color: white;
}
.cyan-btn:active {
  background-color: #00a7a7;
  border-color: white;
}


.purple-btn {
  background-color: #7d50a3;
  border-color: #4c2c69;
  color: white;
  transition: transform 0.5s;
}
.purple-btn:hover {
  background-color: #4c2c69;
  color: white;
}
.purple-btn:focus {
  background-color: #4c2c69;
  border-color: white;
}
.purple-btn:active {
  background-color: #7d50a3;
  border-color: white;
}


.icon-button {
  display: inline-flex;
  align-items: center;
}

.custom-link {
  color: rgb(255, 255, 255); /* Change the color to your desired value */
  text-align: center;
  color: white !important
}



a{
  text-decoration: none !important;
}



/*=============================================
=            accordion btn            =
=============================================*/
.btn-plain{
  width: auto;
  border: none;
  padding: 0;
}

.btn-plain:hover{
  background-color: #bfbfbf68;
 
}


/*=====  End of accordion btn  ======*/


