*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* FIRST PAGE */

.login-contanier{
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-main{
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 50%;
    text-align: center;
    background-color: white;
    margin-bottom: 20px;
}
.logo1-btn{
   width: 240px;
   margin-bottom: 10px;
}
.logo-header{
     top: 0;
     position: absolute;
     margin: 30px;
}

.login-form{
    margin-left: -20px;
    margin-top: 180px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
} 

.login-input{
    width: 40vw;
    outline: none;
    
}
.login-input::placeholder{
    padding-left: 20px;
}

.login-btn{
    margin: 50px;
    width: 140px;
    height: 40px;
    outline: none;
    border: none;
    transition: all 0.3s ease-in-out;
    color: white;
    background-color:#afba15 ;
}
.login-btn:hover{
transform: scale(1.1);
border-radius: 20px;

}
/* SECONDPAGE */

.login-text{
    position: absolute;
    top: 200px;
    border-bottom:1px solid #afba15;
    width: 100%;
}

.feature-buttons{
    position: absolute;
    display: flex;
     justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    top: 310px;
    margin-bottom: 20px;
}
.feature-buttons>div{
    margin:  15px 30px ;
    cursor: pointer;
    width: 140px;
    height: 140px;
    border-radius: 10px;
    transition: all 0.3 ease-in;
    color: white;
}
.feature-buttons>div:hover{
    transform: scale(1.1);
 }
 

.icons-style{
    font-size: 40px;
    margin-top: 20px;
}
.profile-contanier{
    object-fit: contain;
   border-radius: 50%;
    height: 140px;
    width: 140px;
    position: absolute;
    top: 40px;
    vertical-align: middle;
}
.profile-main{
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-update{
    position: absolute;
    top: 145px;
    margin-left: 45px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    color: white;
    cursor: pointer;
    background-color: #0c0847;
}
.settings-icon-contanier{
    position: absolute;
    top: 50px;
    right: 50px;
    height:25px;
    width:25px;
    background-color:#afba15;
    opacity:0.7;
    cursor: pointer;
}
.settings-icon{
   color: white;
   text-align: center;
   cursor:pointer;
}

.overlay-contanier{
        height: 100%;
        width: 100%;
        background-color: black;
        opacity: 0.6;
        position: absolute;
        bottom: 80px;
    
    }
    .modal-contanier{
        height: 150px;
        width: 100%;
        position: absolute;
        bottom: 0px;
        color: black;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        background-color:white;
       
    }


.modal-icons-contanier{
    display: flex;
    margin-top: 25px;
}
.modal-icons-contanier>div{
    cursor: pointer;
    
}
.bottom_icon{
    font-size: 20px ;
    color:#0c0847;
    margin-top: 10px;
}

 .bottom-icon1{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: white;
    margin-right: 45px;
    cursor: pointer;

   
}



/* MEDIA QUERIES */
@media screen and (max-width:767px){
    .login-main{
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
    }
    
}

/* Phones */
@media screen and (max-width: 600px) {
    
}

/* Portrait Tablet and Large Phones */
@media screen and (min-width: 600px) {
  
}

/* Landscape Tablet */
@media screen and (min-width: 768px) {
 
}

/* Laptop/Desktop */
@media screen and (min-width: 992px) {
 
}

/* Large Laptop/Desktop */
@media screen and (min-width: 1200px) {
  
}
    


