.error {
  color: red;
}

#html5qr-code-full-region {
  width: 100%;
}

.Result-container {
  margin: auto;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;

  text-align: center;
}

.Result-header {
  font-size: 16pt;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  overflow: scroll;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); */
}
th,
td {
  padding: 2%;
  background-color: rgba(255, 255, 255, 0.2);
  color: rgb(0, 0, 0);
}

th {
  text-align: left;
}

thead th {
  background-color: #55608f;
}

tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
/* tbody td:hover:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -9999px;
  bottom: -9999px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
} */
