* {
    font-family: 'Poppins', sans-serif;
}

.nav {
    width: 100%;
    display: flex;
    list-style-type: none;
    font-size: 15px;
    font-weight: 600;
    justify-content: center;
    color: #32797e;
    margin-bottom: 5px;
  }
  
  .link,
  .link:visited {
    padding: 8px 10px;
    font-size: 15px;
    font-weight: 600;
    margin: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .session_cont {
    margin: 0 auto;
    max-width: 100%;
    margin-top: 5rem;
    width: 700px;
    padding: 10px;
  }
  
  .subCont {
    background-color: #fff;
    border-top: 1px solid #dee2e6;
    margin: 0 auto;
    min-height: 70vh;
  }
  
  .nav .link:hover,
  .nav .link:active {
    padding: 8px 9px;
    border: 1px solid;
    border-color: #dee2e6 #dee2e6 #fff;
  }
  
  .calendar {
    width: 100%;
  }
  
  .calendar_inputs {
    text-align: left;
    margin-top: 40px;
    width: 100%;
    margin: 0 auto;
  }
  
  .active {
    padding: 8px 9px;
    border: 1px solid;
    border-color: #dee2e6 #dee2e6 #fff;
  }
  
  @media (max-width: 768px) {
    .session_cont {
      width: 100%;
      padding: 10px;
    }
  
    .subCont {
      margin: 0;
    }

    .link,
    .link:visited {
    font-size: 12px; /* Adjust the font size to your preference */
  }
  }
  