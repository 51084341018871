/* .mobile-style {
  margin-top: 1%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: white;
  border-radius: 25px;
  padding: 1rem;
} */

.center {
  text-align: center;
  flex-direction: row;
}

.space-between {
  justify-content: space-between;
}
