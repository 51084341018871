.signup-center {
  position: relative;
  text-align: center;
  justify-content: center;
  display: flex; /* Add flex display */
  align-items: center; /* Align items vertically */
  justify-content: center; /* Center items horizontally */
}

.signup-center .row {
  display: flex;
  justify-content: center;
}

.signup-center .row Button {
  margin: 0 10px; /* Add margin to create a gap between buttons */
}

/*only in case of bottom bar*/
.signup-buffer {
  margin-bottom: 15%;
}

.titles {
  position: relative;
  width: 75%;
  margin: auto;
  text-align: center;
  justify-content: center;
}
.titles h1 {
  font-weight: 700;
  font-size: 3.5rem;
  letter-spacing: -0.1rem;
}
.titles h2 {
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: -0.1rem;
  color: #0c0847;
  margin-top: 5%;
}

.subtitles {
  margin-top: 0%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.017em;
  justify-content: center;
  display: flex; /* Add flex display */
  align-items: center; /* Align items vertically */
  color: #FFFFFF;

}

.signup-btn {
  width: 30%;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 2%;
  font-size: 1rem;
  border-radius: 8px;

  background-color: #afba15;
  border-color: #02001a;
  color: #0c0847;
}
.signup-btn:hover {
  background-color: #02001a;
  border-color: white;
  color: white;
}
.signup-btn:focus {
  background-color: #02001a;
  border-color: white;
}
.signup-btn:active {
  background-color: #0c0847;
  border-color: white;
}

.signup {
  padding: 30px;
}
.signup-confirm {
  /* margin-top: 2%;
  margin-bottom: 2%;
  font-size: 1rem;
  border-radius: 8px;
  background-color: #0c0847;
  border-color: #02001a;
  color: white; */
/* 
  width: 185px;
  height: 81px;
  top: 757px; */

  background: #2E2687;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 50px;

}
.signup-confirm:hover {
  background-color: #02001a;
  border-color: white;
  color: white;
}
.signup-confirm:focus {
  background-color: #02001a;
  border-color: white;
}
.signup-confirm:active {
  background-color: #0c0847;
  border-color: white;
}

.signup-skip {
  width: 60%;
  float: center;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 2%;
  font-size: 1rem;
  border-radius: 8px;

  background-color: white;
  border-color: #02001a;
  color: #0c0847;
}
.signup-skip:hover {
  border-color: white;
}
.signup-skip:focus {
  border-color: white;
}
.signup-skip:active {
  border-color: white;
}

.signup-dropdown {
  border-radius: 0.25rem;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: lightgray;
}
.signup-dropdown:hover {
  background-color: gray;
}

.auth-error {
  color: red;
  font-size: 20px;
  float: center;
  text-align: center;
  justify-content: center;
  font-weight: 600;
  margin: auto;
}

.success {
  color: #afba15;
  font-size: 20px;
  float: center;
  text-align: center;
  justify-content: center;
  font-weight: 600;
  margin: auto;
}

.page-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* Ensure the wrapper is behind other elements */
}

.signup-input {
  width: 383px;
  height: 63px;
  left: 528px;
  top: 387px;

  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.placeholder-input::placeholder {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 38px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.017em;

  color: #2E2687;

}

