/* div {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: white;
  background-color: #afba15;
} */

/* * {
  box-sizing: border-box;
} */

.loading {
  /* position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  left: 0;
  right: 0;
  z-index: 100;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%; */
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: white;
  background-color: #fffffe;
}

.icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  left: 0;
  right: 0;
  z-index: 100;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
}

.wheat {
  width: 15%;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(0deg);
    animation-direction: reverse;
  }
  75% {
    transform: rotate(20deg);
    animation-direction: reverse;
  }
  100% {
    transform: rotate(0deg);
  }
}
