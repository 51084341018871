.chat_cont{
    height: 100%;
    background-color: #f5f5f5;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 8px;
}
.chat_cont > div:first-child{
    height: 90%;
}
.chat_input{
    /* position:absolute ; */
    height: 40px;
    border-radius: 25px;
    background-color: #5f81a6;
    margin-bottom: auto;
    display: flex;
    align-items: center;
    padding: 0 8px;
    text-align: left;
}
.chat_input > div{
    margin: 0;
}

.typing{
    height: 25px;
    margin-top: 5px;
}

.messages{
    height: 85%;
}

.meMsg{
    padding: 2px 5px;
    border-radius: 8px;
    background-color: #6daece;
    margin-bottom: 5px;
    text-align: right;
    max-width: 250px;
    position: relative;
    margin-left: auto;
    text-align: left;
}

.meMsg::after{
    content: attr(data-time);
    font-size: 10px;
    color: grey;
    position: absolute;
    bottom: 2px;
    right: 2px;
}

.allmsg{
    overflow-y:scroll;
    height: 100%;
}

.otherMsg{
    padding: 2px 5px;
    border-radius: 8px;
    background-color: #6dce9e;
    margin-bottom: 5px;
    max-width: 250px;
    position: relative;
   

}
.otherMsg::after{
    position: absolute;
    content: attr(data-time);
    font-size: 10px;
    color: grey;
    bottom: 2px;
    right: 2px;
}

/* Phones */
@media screen and (max-width: 600px) {
    
}

/* Portrait Tablet and Large Phones */
@media screen and (min-width: 600px) {
  
}

/* Landscape Tablet */
@media screen and (min-width: 768px) {
 
}

/* Laptop/Desktop */
@media screen and (min-width: 992px) {
 
}

/* Large Laptop/Desktop */
@media screen and (min-width: 1200px) {
  
}