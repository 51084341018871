.shop-items {
    margin: 0 2% 2% 0;
    width: 100%;
    min-height: 8rem;
    position: relative;
    padding-bottom: 1.5rem;
  }
  
  .icons {
    flex: 1;
    text-align: right;
  }
  
  .shop-items .button {
    position: absolute;
    bottom: 1.5rem; /* Adjust this value as needed */
    right: 2%; /* You can adjust the right distance as needed */
  }

  /* Style radio buttons */
input[type="radio"] {
  display: none; /* Hide the actual radio input */
}

/* Style custom radio buttons (circles) */
input[type="radio"] + label::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #A5B828; /* Green color for the border of the circle */
  border-radius: 50%; /* Make it circular */
  margin-left: 5px; /* Add space between options */
  margin-right: 5px; /* Add space between options */
  vertical-align: middle;
}

/* Style radio button labels */
input[type="radio"] + label {
  font-size: 16px; /* Increase text size */
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

/* Style radio button labels when selected */
input[type="radio"]:checked + label::before {
  background-color: #A5B828; /* Change the circle color when selected */
}

/* Increase text field size */
input[type="text"] {
  width: 100%; /* Adjust the width as needed */
  padding: 10px; /* Adjust padding as needed */
  font-size: 16px; /* Increase text field text size */
  margin-bottom: 10px; /* Add space between text fields */

}

  
  
  