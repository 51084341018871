.web-center {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Source Sans Pro", sans-serif;
}

.avatar-name-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Add margin bottom for spacing */
}

.building-function-container p {
  display: flex;
  align-items: center;
  margin-bottom: 10px; 
  font-weight: bold;
  white-space: nowrap; /* Prevents text from wrapping to a new line */

}

.user-account {
  justify-content: center;
}

/* Phones */
@media screen and (max-width: 600px) {
  .user-account {
    max-width: 100%;
  }
  .web-center {
    max-width: 100%;
  }
}

/* Portrait Tablet and Large Phones */
@media screen and (min-width: 600px) {
  .user-account {
    max-width: 100%;
  }
  .web-center {
    max-width: 100%;
  }
}

/* Landscape Tablet */
@media screen and (min-width: 768px) {
  .user-account {
    max-width: 90%;
  }
  .web-center {
    max-width: 90%;
  }
}

/* Laptop/Desktop */
@media screen and (min-width: 992px) {
  .user-account {
    max-width: 70%;
  }
  .web-center {
    max-width: 70%;
  }
}

/* Large Laptop/Desktop */
@media screen and (min-width: 1200px) {
  .user-account {
    max-width: 50%;
  }
  .web-center {
    max-width: 60%;
  }
}

.profile {
  display: block;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 15px;
  background-color: white;
}

.sub-selecton {
  max-width: 300px;
}

.disclaimer {
  color: #32797e;
  background-color: #c6e5e7;
  border-color: #32797e;
  padding: 5px;
}

.disclaimer-btn {
  margin-top: 2%;
  width: 100%;
}

.large-img {
  margin-top: 2%;
  margin-bottom: 2%;
  width: 100%;
  height: auto;
}

.small-img {
  margin-top: 2%;
  margin-bottom: 2%;
  max-width: 60%;
  height: auto;
}

.white {
  background-color: white;
  color: #0c0847;
}

.green {
  background-color: #afba15;
  color: white;
}

.blue {
  background-color: #0c0847;
  color: white;
}

/*plan to save*/
.countdown {
  color: #0c656b;
  font-size: 200%;
  font-weight: 200;
  padding: 5px;
}

.coundown-card {
  max-width: 50%;
  margin: auto;
}

.label {
  background-color: #0c656b;
  color: white;
  font-weight: 600;
  padding-left: 15.5px;
  padding-right: 15.5px;
}

.img-overlay {
  bottom: 20%;
  background-color: #0c0847;
  color: white;
  padding-left: 10%;
  padding-right: 10%;
}

.pts-info {
  text-align: center;
  border-radius: 25px;
  background-color: #0c0847;
  color: white;
  padding: 4%;
  margin-bottom: 2%;
}

.pts-p-div {
  text-align: center;
  float: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pts-p {
  font-size: 3;
}

.pts-container {
  position: relative;
}

.text-block {
  position: absolute;
  top: 10%;
  width: 80%;
  justify-content: center;
  align-items: center;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.text-block p {
  font-size: large;
}

.support {
  width: 100%;
  background-color: #afba15;
  color: white;
  padding-top: 2%;
  padding-bottom: 2%;
}
