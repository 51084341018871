*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/*  QUESTION 2 */

.question-contanier{
    display: flex;
    justify-content: center;
    align-items: center;
}

.question-subcontanier{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    margin-top: 30px;
}

.onboard-message{
    text-align: center;
}
.logo1-btn{
   width: 240px;
   margin-bottom: 10px;
}
.consultant_logo-header{
     width: 100%;
     
}.question-main{
    border-radius: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    width: 50%;
    text-align: center;
    background-color: white;
    padding: 40px;

}
.consultant_previewImg{
    object-fit: cover;
}

/* .consut */
.default-form2{
    margin-top: 180px;
}
.input2{
    margin-top: 10px;
    width: 80%;
    text-align: center;
}

 .form-group{
    margin-top: 40px;
   /* padding: 0px 40px; */
   position: relative;
 }
 
  .form-control{
    width: 100%;
    /* text-align: left; */
  } 
  
.form-label{
    float: left;
    text-align: left;
}

.onboard-cont{
    width: 70%;
    background-color: white;
}

/* .modal{
    position: absolute;
    background-color: black;
    width: 100vw;
    top: 0
} */

 
.question2-btn{
    margin: 50px;
    width: 140px;
    height: 40px;
    outline: none;
    border: none;
    transition: all 0.3s ease-in-out;
    color: white;
    background-color:#afba15 ;
}
.question2-btn:hover{
transform: scale(1.1);
border-radius: 20px;

}
.different{
    margin-bottom: 10px;
}
.eye-style{
    font-size: 20px; 
    cursor: pointer;
    /* margin-top: -28px; */

    position: absolute;
    right: 10px;
    bottom: 8px
}
.fixed-service-money{
    margin-top: -38px;
    position: absolute;
    right: 100px;
}
/* MEDIA QUERIES */



@media screen and (max-width:767px) {
    .question-main{
        width: 100vw;
        height: 100vh;
    }
    
    .default-form{
        margin-top: 0px;
    }
    .question-subcontanier{
        width: 100vw;
        height: 100vh;
    }
    .error-div-contanier{
        margin: 0px 15px 26px;
    }
    .eye-style{
        right: 50px;
    }
    .fixed-service-money{
        right: 50px;
    }
    
}

@media screen and (max-width:420px){
    .default-form{
        margin-top: 160px;
    }
    
    .error-message{
        margin-right: 30px;
    }
}

/* END OF STAGE 2 */


/* Phones */
@media screen and (max-width: 600px) {
    
}

/* Portrait Tablet and Large Phones */
@media screen and (min-width: 600px) {
  
}

/* Landscape Tablet */
@media screen and (min-width: 768px) {
 
}

/* Laptop/Desktop */
@media screen and (min-width: 992px) {
 
}

/* Large Laptop/Desktop */
@media screen and (min-width: 1200px) {
  
}

    
/* MEDIA QUERIES */
@media screen and (max-width:767px){
    .question-main{
        width: 100vw;
        height: 100vh;
    }
    .question-buttons{
        display: flex;
        padding: 0px 10px;
        justify-content: space-between;
      
    }
    .default-form{
        margin-top: 0px;
    }
    
}

@media screen and (max-width:420px){
    .default-form{
        margin-top: 160px;
    }
    .question-buttons{
        width: 100vw;
    }
    .question-buttons>button{
       margin: 20px;
       
    }
    .error-message{
        margin-right: 30px;
    }
}
